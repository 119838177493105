<template>
    <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
      <v-row justify="center" class="mb-8">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="candidate-details__title">Detalle del candidato</h1>
          <v-chip color="#466be3" dark> {{info.origen}} </v-chip>
        </v-col>
        <v-col class="px-0" cols="12">
          <v-card elevation="0" class="px-6 py-6" outline style="border-radius: 15px"> 
            <p class="text-left"> Datos del candidato </p>
            <v-row>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Nombre completo </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.post_title }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Número de documento </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.tipo_de_documento }} {{ info.numero_de_documento }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Fecha de expedición </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.fecha_expedicion }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Correo electrónico </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.correo_electronico }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Teléfono</i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.telefono }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6">
                <p class="mb-1 candidate-details__data_title">
                  <i> Fecha de nacimiento </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.fecha_nacimiento }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="4">
                <p class="mb-1 candidate-details__data_title">
                  <i> ¿Primera vez en BRM SAS? </i>
                </p>
                <p v-if="info.primera_vez_en_brm" class="text-left mb-1 candidate-details__data_txt">
                  {{ info.primera_vez_en_brm }}
                </p>
                <p v-else class="text-left mb-1 candidate-details__data_txt" style="font-size: 14px; color: #8f8f8f;">
                  <i> Sin información </i>
                </p>
                <v-divider></v-divider>
              </v-col>
               <v-col cols="4">
                <p class="mb-1 candidate-details__data_title">
                  <i> Tratamiento de datos </i>
                </p>
                <p v-if="info.tratamiento_de_datos" class="text-left mb-1 candidate-details__data_txt">
                  {{ info.tratamiento_de_datos }}
                </p>
                <p v-else class="text-left mb-1 candidate-details__data_txt" style="font-size: 14px; color: #8f8f8f;">
                  <i> Sin información </i>
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="4">
                <p class="mb-1 candidate-details__data_title">
                  <i> Origen </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                 Trabaja con nosotros
                </p> 
                <v-divider></v-divider>
              </v-col>
              <v-col cols="4">
                <p class="mb-1 candidate-details__data_title">
                  <i> Origen </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                 Trabaja con nosotros
                </p> 
                <v-divider></v-divider>
              </v-col>
              <v-col cols="4">
                <p class="mb-1 candidate-details__data_title">
                  <i> Fecha de registro </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.post_date }}
                </p>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="4">
                <p class="mb-1 candidate-details__data_title">
                  <i> Vacante </i>
                </p>
                <p class="text-left mb-1 candidate-details__data_txt">
                  {{ info.vacante }}
                </p>
                <v-divider></v-divider>
              </v-col>
                   <v-col cols="4">
                <p class="mb-1 candidate-details__data_title">
                  <i> Hoja de vida </i>
                </p>
                <p v-if="info.hoja_de_vida" class="text-left mb-1 candidate-details__data_txt">
                  <v-btn
                    block
                    color="#466BE3"
                    small
                    outlined
                    @click="downloadCV(info.hoja_de_vida)"
                  >
                    <strong> Descargar archivo </strong>
                  </v-btn>
                </p>

                <p v-else class="text-left mb-1 candidate-details__data_txt" style="font-size: 14px; color: #8f8f8f;">
                  <i> Sin información </i>
                </p>
                
                <v-divider></v-divider>
              </v-col>
            </v-row>
           </v-card>
        </v-col>
        <!-- <v-col class="pl-0" cols="6">
          <v-card elevation="0" class="px-6 py-6" outline style="border-radius: 15px">
            <p class="text-left"> Tipificación de estados </p>
            <v-form ref="form">
              <v-row>
              <v-col cols="12">
                 <v-textarea :rules="[
                      (v) => !!v || 'Este campo es requerido',
                      (v) =>
                        v.length <= 400 || 'Supera el límite de caracteres ',
                    ]" counter="" label="Registrar novedad" color="#466be3" rows="6" v-model="form.description"> </v-textarea>
              </v-col>
              <v-col cols="7">
                <v-autocomplete label="lista de estados" color="#466be3" :items="listStatus" v-model="form.status" ></v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-btn class="mt-3" color="#466BE3" rounded dark block @click="addDescription"> <strong> Registrar  </strong> </v-btn>
              </v-col>
            </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col class="pr-0" cols="6">
           <v-card  max-height="400" height="390" elevation="0" class="px-6 py-6 candidate-details__table" outline style="border-radius: 15px">
            <p class="text-left"> Lista de estados del candidato</p>
            <v-simple-table theme="dark">
              <thead>
                <tr>
                  <th class="text-left">
                    Nombre reclutador
                  </th>
                  <th class="text-left">
                    Descripción
                  </th>
                  <th class="text-left">
                    Estado
                  </th>
                  <th class="text-left">
                    Fecha
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in listDescriptions"
                  :key="item.name"
                >
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-left">{{ item.description }}</td>
                  <td class="text-left"> <v-chip color="#07ba56" small dark> {{ item.status }} </v-chip> </td>
                  <td class="text-left">{{ item.date }}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-alert v-if="listDescriptions.length == 0" text type="info" class="mt-3">
              No se encontraron resultados
            </v-alert>

          </v-card>
        </v-col> -->
      </v-row>
      </div>
      <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. -->
      </div>
</template>
<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      listDescriptions: [],
      listStatus: ["Pendiente entrevista", "Contratado", "No contratado", "Sin respuesta"],
      form: {
        status: null,
        description: ""
      },
      info: {
        post_title: "Fabio Alejandro Montenegro Zambrano	",
        tipo_de_documento: "CC",
        numero_de_documento: "1085260376",
        fecha_expedicion: "2006-08-30",
        correo_electronico: "a@a.com",
        telefono: "(+57)3043898449",
        fecha_nacimiento: "1987-08-30",
        hoja_de_vida: "https://brm.technoar.co/wp-content/uploads/elementor/forms/63e3dc9941265.pdf",
        primera_vez_en_brm: "Si",
        post_date: "2023-02-08 17:32:09",
        tratamiento_de_datos: "Si",
        vacante: "Telemercaderista"
      },
      search: "",
    };
  },
  methods: {
    downloadCV(url) {
      window.open(url, "_blank");
    },
    addDescription(){
      this.listDescriptions.unshift({
        name: "Angie Natalia Rodriguez Garcia",
        status: this.form.status,
        description: this.form.description,
        date: new Date().toLocaleString()
      })
      this.$refs.form.reset();
    }

  },
  mounted() {

  },
  created(){
    var data = localStorage.getItem("infoCandidate");
    this.info =  JSON.parse(data);
  }
};
</script>
<style>
.candidate-details__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.candidate-details__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}

.candidate-details__table {
  height: 420px;
  overflow-y: scroll;
}

</style>
